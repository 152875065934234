var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "h1",
      { class: _vm.$style.pageTitle },
      [_vm.isCreate ? [_vm._v("おしらせ作成")] : [_vm._v("おしらせ編集")]],
      2
    ),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _vm.loading
                ? _c(
                    "div",
                    { staticClass: "text-center" },
                    [_c("a-spin", { attrs: { tip: "Loading..." } })],
                    1
                  )
                : _c(
                    "a-form-model",
                    {
                      ref: "ruleForm",
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rules,
                        "label-col": { span: 4 },
                        "wrapper-col": { span: 14 }
                      }
                    },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          ref: "title",
                          attrs: { label: "タイトル", prop: "title" }
                        },
                        [
                          _c("a-input", {
                            on: {
                              blur: function() {
                                _vm.$refs.name.onFieldBlur()
                              }
                            },
                            model: {
                              value: _vm.form.title,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "title", $$v)
                              },
                              expression: "form.title"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          ref: "category",
                          attrs: { label: "カテゴリ", prop: "category" }
                        },
                        [
                          _c("a-input", {
                            on: {
                              blur: function() {
                                _vm.$refs.name.onFieldBlur()
                              }
                            },
                            model: {
                              value: _vm.form.category,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "category", $$v)
                              },
                              expression: "form.category"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { ref: "date", attrs: { label: "日付", prop: "date" } },
                        [
                          _c(
                            "a-config-provider",
                            { attrs: { locale: _vm.locale } },
                            [
                              _c("a-date-picker", {
                                model: {
                                  value: _vm.form.date,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "date", $$v)
                                  },
                                  expression: "form.date"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          ref: "description",
                          attrs: { label: "本文", prop: "description" }
                        },
                        [
                          _c("a-textarea", {
                            attrs: { rows: 10 },
                            model: {
                              value: _vm.form.description,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "description", $$v)
                              },
                              expression: "form.description"
                            }
                          }),
                          _c("p", [_vm._v("※ リンクの書き方: [[ラベル]](URL)")])
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          ref: "is_public",
                          attrs: { label: "公開設定", prop: "is_public" }
                        },
                        [
                          _c(
                            "a-radio-group",
                            {
                              model: {
                                value: _vm.form.is_public,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "is_public", $$v)
                                },
                                expression: "form.is_public"
                              }
                            },
                            [
                              _c("a-radio", { attrs: { value: 0 } }, [
                                _vm._v("非公開")
                              ]),
                              _c("a-radio", { attrs: { value: 1 } }, [
                                _vm._v("公開")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { "wrapper-col": { span: 14, offset: 4 } } },
                        [
                          _c("div", { staticClass: "clearfix" }, [
                            !_vm.isCreate
                              ? _c(
                                  "div",
                                  { staticClass: "float-left" },
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        attrs: {
                                          type: "danger",
                                          loading: _vm.btnLoading
                                        },
                                        on: { click: _vm.onDelete }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    削除\n                  "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { class: { "float-right": !_vm.isCreate } },
                              [
                                _c(
                                  "a-button",
                                  {
                                    class: _vm.$style.filledBtn,
                                    attrs: {
                                      type: "primary",
                                      loading: _vm.btnLoading
                                    },
                                    on: { click: _vm.onSubmit }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.submitString) +
                                        "\n                  "
                                    )
                                  ]
                                ),
                                _c(
                                  "router-link",
                                  { attrs: { to: _vm.backPath } },
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        staticStyle: { "margin-left": "10px" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                      戻る\n                    "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }